import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import button from 'react-bootstrap/Button';
import ParticleField from 'react-particles-webgl';
import { Parallax } from 'react-parallax';
import bkgrnd1 from './images/body_bkgrnd.jpg';
import bkgrnd2 from './images/body_alt_bkgrnd.jpeg';
import cycleLogo1 from './images/cyclelogos_triangle.svg';
import cycleLogo2 from './images/cyclelogos_taskList.svg';
import cycleLogo3 from './images/cyclelogos_odometer.svg';
import cycleLogo4 from './images/cyclelogos_checkmark.svg';
// import CountUp from './countUp';
import CountUp from 'react-countup';
// import LineTo, { SteppedLineTo } from 'react-lineto';
import Aos from "aos";
import "aos/dist/aos.css";

const particleStyles = {
  height: "100vh", width: "100%", position: "absolute"
}

function App() {
  useEffect(() => {
    Aos.init({duration: 500});
  }, []);
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        
            <a
              className="App-link"
              href="mailto:rfi@denialytics.com"
              target="_blank"
              rel="noopener noreferrer"
            >
<button class="btn btn-primary">
              <img src="mail.svg" className="mailIcon" alt="Mail Icon" />
              Request More Information Today
              </button>
            </a>
        
        
      </header>
      <div id="mainView">

        <Parallax bgImage={bkgrnd1} strength={500}>
          <div style={particleStyles}>
        <ParticleField 
          config={{
            showCube: false,
            dimension: '3D',
            boundaryType: 'bounce',
            velocity: .5,
            lines: {
              colorMode: 'solid',
              color: '#1B7FC2',
              transparency: .6
            },
            particles: {
              colorMode: 'solid',
              color: '#1B7FC2',
              transparency: .6,
              shape: 'circle',
              count: '50'
            },
            cameraControls: {
              enabled: false
            }
          }}
        />
      </div>
      {/* --- First Section --- */}
      <section id="aboveFold" style={{height: 500}}>
        <div class="bigText" data-aos="fade-in">
          <h1><span class="orWords">Avoid Denials</span>
            <br/>Intelligently
          </h1>
        </div>
        <div class="blurBkgrnd text-box" data-aos="fade-in">
          <h3>Eliminate payor claim denials with <span class="orWords">real-time artificial intelligence</span> embedded in your current technology</h3>
        </div>
        </section>
        </Parallax>
        {/* --- Next Section --- */}
        <Parallax bgImage={bkgrnd2} strength={500}>
        <section id="aiBkgrnd">
          
            <div class="blurBkgrnd text-box" data-aos="fade-in">
              <h2>
              The current state of healthcare revenue cycle operations is labor intensive, prone to errors 
              and supported by old-world 80’s and ‘90s technology. Yet, new, proven technologies exist to address this problem.
              </h2>
            </div>
            
        </section>
        </Parallax>
        {/* --- Stats Section --- */}
        <section id="numBkgrnd"> 
          <h2 data-aos="fade-in">
          
            <span class="orWords">Our Patent Pending Technology eliminates payor denials at the source</span>
            <br />To deliver compelling Financial Benefits:<br />
                  Reduce labor costs, eliminate data errors, and accelerate cash
          
          </h2>
          <div id="stats" class="row">
            <div class="col-12 col-lg-4 mb-4" data-aos="fade-in">
              <CountUp start={0} end={68} delay={0} suffix="%">{({ countUpRef }) => (
                // eslint-disable-next-line
                <h1 class="orWords statNum" ref={countUpRef}></h1>
              )}
                </CountUp>
              <h3>of all denied claims are unnecessary</h3>
            </div>
            <div class="col-12 col-lg-4 mb-4" data-aos="fade-in">
            <CountUp start={0} end={96} delay={0} suffix="%">{({ countUpRef }) => (
                // eslint-disable-next-line
                <h1 class="orWords statNum" ref={countUpRef}></h1>
            )}
                </CountUp>
              <h3>of all denied claims are overturned and ultimately paid correctly</h3>
            </div>
            <div class="col-12 col-lg-4 mb-4" data-aos="fade-in">
              <h1 class="orWords statNum"><CountUp start={0} end={25} delay={0} prefix="$"></CountUp> - <CountUp start={0} end={30} delay={0} prefix="$"></CountUp></h1>
              <h3>average cost to appeal each denied claim</h3>
            </div>
          </div>
          <h2 data-aos="fade-in">
            <span class="orWords">
            Our plan: Use our technology to avoid denials across the revenue cycle for every line of business
            </span>
            <br />
            Proactively eliminate conditions that lead to a payor denying claim reimbursement
          </h2>
        </section>
        {/* --- What is it section --- */}
        <Parallax bgImage={bkgrnd1} strength={800}>
        <section id="whatFor">
          <div class="row">
            <div class="col-6 border-right border-white">
            <ul class="orWords font-weight-bold">
              <li><h3 class="font-weight-bold" data-aos="fade-in">Scheduling</h3></li>
              <li><h3 class="font-weight-bold" data-aos="fade-in">Point-of-service registration</h3></li>
              <li><h3 class="font-weight-bold" data-aos="fade-in">Case Management</h3></li>
              <li><h3 class="font-weight-bold" data-aos="fade-in">HIM/Coding</h3></li>
              <li><h3 class="font-weight-bold" data-aos="fade-in">Business Office</h3></li>
              <li><h3 class="font-weight-bold" data-aos="fade-in">Inpatient, Outpatient, Physician Office</h3></li>
            </ul>
            </div>
            <div class="col-6 my-auto">
              <h2 class="ml-4 font-weight-bold" data-aos="fade-in">Across the House<br /><br /> Across ALL Lines of Business</h2>
            </div>
          </div>
        </section>
        </Parallax>
        {/* --- Execution Section --- */}
        <section id="execution">
            <h2 class="orWords font-weight-bold" data-aos="fade-in">
            Execution: Disrupt the current denials management process with new-world technology
            </h2>
            <h1 class="font-weight-bold text-align-center" data-aos="fade-in">
              The goal is <span class="orWords">Zero Denials<span class=""><sup>®</sup></span></span>
            </h1>
        </section>

        {/* --- 3 Bullet Points --- */}
        <Parallax bgImage={bkgrnd1} strength={500}>
        <section id="threeBulletPoints" class="blurBkgrnd">
          <div class="row px-5">
          <div class="col-12 col-lg-4 mb-4 mb-lg-0 border-left border-white pl-5" data-aos="fade-in">
            <h2 class="orWords">Real-time AI and active machine learning technology</h2>
            <h3>Intelligent algorithms
digest historic patient
information, claims
remittance data, clinical
data and payor
reimbursement terms to
become <span class="orWords font-weight-bold">SELF-AWARE</span></h3>
          </div>

          <div class="col-12 col-lg-4 mb-4 mb-lg-0 border-left border-white pl-5" data-aos="fade-in">
            <h2 class="orWords"><span class="font-weight-bold">Propensity to Deny<sup>®</sup></span> Score
for each active
patient</h2>
            <h3>This unique score is an
indication to the staff of the potential for the
patient’s claim to be fully or
partially denied by the payor</h3>
          </div>

          <div class="col-12 col-lg-4 mb-4 mb-lg-0 border-left border-white pl-5" data-aos="fade-in">
            <h2 class="orWords">Constant goal is
<span class="font-weight-bold"> Zero Denials<sup>®</sup></span></h2>
            <h3>PTD score dynamically
adjusts to changes in 
patient status along the
continuum of care, alerting
staff with new calls to action
for denial prevention</h3>
          </div>
          </div>
        </section>
        {/* --- Cycle Section --- */}
        <section id="cycleSection">
          <div class="bigText px-5" data-aos="fade-in">
          <h1>Drive to <span class="orWords font-weight-bold">Zero Denials<sup>®</sup></span> with real-time artificial intelligence</h1>
          </div>
          <div id="cycle" class="mt-5">
              <div id="top-of-cycle" class="row mb-5 learning">
                <div class="col-12 cycle-item blurBkgrnd" data-aos="fade-in">
                  <h3 class="font-weight-bold">Continual learning from 
patient’s status and payors’ 
payment conduct
</h3>
</div>
              </div>
              <div class="row row-cols-1 row-cols-lg-5 px-3 mx-auto" data-aos="fade-in">
                <div class="card blurBkgrnd cycle-item align-self-stretch predictive mb-4 mx-auto" >
                  
                  <div class="cycleLogo"><img src={cycleLogo1} alt="Predictive learning algorithms" /></div>
                  <h3 class="orWords font-weight-bold">Predictive learning
algorithms</h3>
                  <h3>Massive volumes of data expedite supervised learning, beginning with standard ANSI 837 and 835 data</h3>
                
                </div>
                
                <div class="card blurBkgrnd cycle-item align-self-stretch ptd mb-4 mx-auto">
                  
                  <div class="cycleLogo"><img src={cycleLogo2} alt="Propensity to Deny (PTD) Score" /></div>
                  <h3 class="orWords font-weight-bold">Propensity to
Deny<sup>®</sup> (PTD) Score</h3>
                  <h3>The presenting patient is modeled and assigned a PTD score based on historic data to indicate likelihood 
of denial</h3>

                </div>
                {/* <SteppedLineTo from="predictive" to="ptd" fromAnchor="right" toAnchor="left" orientation="h" borderWidth="1" borderColor="#df5029" borderStyle="solid" delay="1"/> */}
                <div class="card blurBkgrnd cycle-item align-self-stretch ddr mb-4 mx-auto">
                  
                  <div class="cycleLogo"><img src={cycleLogo3} alt="Denials Defeat Rules (DDR)" /></div>
                  <h3 class="orWords font-weight-bold">Denials Defeat 
Rules (DDR)</h3>
                  <h3>PTD score factors are matched to DDR, generating calls to action for users to eliminate potential denials</h3>
                  
                </div>
                {/* <SteppedLineTo from="ptd" to="ddr" fromAnchor="right" toAnchor="left" orientation="h" borderWidth="1" borderColor="#df5029" borderStyle="solid" delay="1"/> */}
                <div class="card blurBkgrnd cycle-item align-self-stretch drive mb-4 mx-auto">
                  
                  <div class="cycleLogo"><img src={cycleLogo4} alt="Drive PTD score to zero" /></div>
                  <h3 class="orWords font-weight-bold">Drive PTD score to zero</h3>
                  <h3>Staff follows the calls to action driven workflow to drive the PTD score to “0” prior to claim submission</h3>
                
                </div>
                {/* <SteppedLineTo from="ddr" to="drive" fromAnchor="right" toAnchor="left" orientation="h" borderWidth="1" borderColor="#df5029" borderStyle="solid" delay="1"/> */}
              </div>
              {/* <SteppedLineTo from="drive" to="learning" fromAnchor="right" toAnchor="right" orientation="v" borderWidth="1" borderColor="#df5029" borderStyle="solid" delay="1"/> */}
          </div>
        </section>

        {/* --- Footer --- */}
        <footer id="footer" class="mt-5">
            <div class="row border-bottom border-white pb-5 align-items-center">
              <div class="col-md-3"></div>
              <div class="col-12 col-md-3 mr-5">
                <img width="300" src={logo} alt="Denialytics Logo"></img>
              </div>
              <div class="col-12 col-md-3 ml-5">
                <p><a href="denialytics.com" class="">denialytics.com</a>
                <br />Denialytics, LLC
                <br />New World Solutions for Healthcare
                </p>
              </div>
              <div class="col-md-3"></div>
              
            </div>
            <div class="row py-3 text-center">
              <div class="col-12">
                <p id="copyright">
                © 2021 Denialytics, LLC. All rights reserved.  The information herein is confidential and not to be distributed without the express written consent of Denialytics, LLC.
                </p>
              </div>
            </div>
        </footer>
        </Parallax>
        
      </div>
    </div>
  );
}

export default App;
